footer {
  border-top: 1px solid rgba(99, 99, 99, 0.15);
}

.iosFooterWrap {
  background: #fff !important;
  width: 64px;
  z-index: 10001;
  position: fixed !important;
  top: 0;
  left: 0;
  justify-content: space-around !important;
  display: flex;

  .footerBack {
    color: #333;
    width: 60px;
    height: 52px;
    border-radius: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  h3 {
    height: auto;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% - 128px);
    margin-right: 64px;
  }

  a {
    width: 64px;
    color: #111;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    small {
      font-size: 10px;
    }
  }
}

.footerWrapper {
  max-width: 925px;
  margin: 0 auto;
  position: relative;
  background: #fff;
  padding: 20px 0 20px 0;

  h3 {
    color: #000;
    font-size: 15px;
    font-weight: 700;
  }

  img {
    margin-top: 15px;
    width: 100px;
    margin-left: 5px;
  }

  ul {
    margin: 0 0 12px;
    padding: 0;

    li {
      font-size: 13px;
      list-style: none;
      color: #000;
      margin: 0 0 8px;
      font-weight: 700;

      span {
        padding-left: 3px;
        color: rgb(66, 66, 66);
      }

      a {
        padding-left: 3px;
        color: rgb(99, 99, 99);
      }
    }
  }

  .footerLinks {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 0 64px;
    word-break: keep-all;

    button.chatQuestion {
      background-color: transparent;
      border: 1px solid rgb(208, 208, 208);
      border-radius: 4px;
      padding: 10px 15px;
      margin: 10px 15px 3px 0px;
    }

    a {
      font-size: 13px;
      margin-right: 12px;
      text-decoration: none;
      font-weight: bold;
      color: rgb(66, 66, 66);
    }

    a:not(:last-child):after {
      content: '';
      border-right: 1px solid rgb(66, 66, 66);
      height: 10px;
      font-size: 10px;
      position: relative;
      margin-top: 2px;
      padding-left: 12px;
    }
  }

  .sns {
    position: absolute;
    top: 15px;
    right: 0px;
    align-items: flex-end;
    padding: 16px 0;
    text-align: right;

    a {
      color: rgb(66, 66, 66);
      font-size: 20px;
      text-decoration: none;
      margin-right: 16px;
    }

    a#appstore {
      font-size: 22px;
      margin-bottom: -1px;
    }

    a#playstore {
      font-size: 18px;
    }
  }

  .copyright {
    color: rgb(99, 99, 99);
    font-size: 12px;
  }
}

@media screen and (max-width: 940px) {
  .footerWrapper {
    padding: 20px;

    h3 {
      font-weight: 700;
      font-size: 13px;
      margin: 16px 0 8px;
    }

    ul {
      margin: 0 0 16px;

      li {
        font-size: 12px;
        margin: 0 0 2px;
        list-style: none;
      }
    }

    .footerLinks {
      margin: 0 0 16px;

      button.chatQuestion {
        margin: 0px 15px 9px 0px;
      }
    }

    .copyright {
      width: 100%;
      font-size: 12px;
      font-weight: 400;
      text-align: center;
      display: inline-block;
    }

    .sns {
      text-align: center;
      padding-bottom: 0;

      a {
        margin: 16px;
      }
    }
  }
}
@media screen and (max-width: 940px) {
  .footerWrapper {
    .footerLinks {
      align-items: flex-start;
      flex-direction: column;
    }
  }
}

@media screen and (max-width: 450px) {
  .footerWrapper {
    padding: 15px 20px;

    ul {
      margin: 0px;
    }

    .sns {
      position: relative;
      top: -6px;
      margin-bottom: 25px;

      a {
        margin: 10px;
      }
    }

    .footerLinks {
      font-size: 12px;
      margin-right: 8px;

      button.chatQuestion {
        margin: 10px 0px 16px 0px;
      }
    }
  }
}
