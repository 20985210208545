.orderContainer {
  max-width: 600px !important;
  min-width: 500px;
  padding: 12px;
  border-radius: 10px;
  background: #ffffff;
  outline: none;

  h3 {
    font-size: 15px;
    margin: 4px 0px 10px;
  }
  
  .buttonClose {
    text-align: right;
  }

  .programInfo {
    padding: 0 13px 13px;
    margin: 0 0 16px;

    img {
      margin: 4px;
      width: 100%;
      height: auto;
    }

    .program_date {
      font-size: 12px;
      color: rgb(77, 77, 77);
    }

    .program_name {
      font-size: 15px;
      font-weight: bold;
    }

    .program_price {
      font-weight: bold;
      font-size: 18px;
      margin: 8px 0 0;

      &:after {
        content: '원';
      }
    }
  }

  .priceWrap {
    border: 1px solid #e9e9e9;
    padding: 12px;
    border-radius: 8px;

    .couponSelectbox {
      position: relative;
      height: 84px;
      font-size: 14px;
      border-bottom: 1px solid #e9e9e9;
      margin-bottom: 13px;

      .selectedCoupon {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid #e9e9e9;
        background-color: #f1f1f1;
        border-radius: 8px;
        padding: 8px 12px;
        height: 40px;
        overflow-y: auto;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        -webkit-transition: background-color, border 0.25s ease;
        -o-transition: background-color, border 0.25s ease;
        transition: background-color, border 0.25s ease;
        z-index: 3;

        &:hover {
          cursor: pointer;
        }

        &.show {
          background-color: white;
          border: 1px solid rgb(24, 24, 24);
        }
      }

      .couponListWrapper {
        position: absolute;
        top: 45px;
        left: 0;
        right: 0;
        height: 0px;
        background-color: white;
        border-radius: 8px;
        overflow-y: auto;
        padding: 0px;
        margin-bottom: 10px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        -webkit-transition: height 0.25s ease;
        -o-transition: height 0.25s ease;
        transition: height 0.25s ease;
        z-index: 2;

        &.show {
          height: 199px;
          border: 1px solid #ccc;
        }
      }
    }

    .priceLine {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 4px;
      border-bottom: 1px solid #e9e9e9;
      margin-bottom: 8px;
      font-size: 14px;

      .price {
        font-size: 16px;
        text-align: right;

        &:after {
          content: '원';
        }

        &.total {
          font-weight: bold;
          font-size: 20px;
        }

        &.discount {
          color: #fe4040;

          &:before {
            content: '-';
          }
        }
      }
    }

    .noBorderBottom {
      border-bottom: none;
      .package {
        margin-right: auto;
        margin-left: 10px;
        text-decoration: underline;
        color: #f04e44;

        &:hover {
          cursor: pointer;
        }
        .icon {
          width: 12px;
          height: 12px;
        }
      }
    }

    .paymentMethods {
      justify-content: space-between;
      
      label span {
        font-size: 14px;
      }

      .kakaopayButton {
        padding: 0;
      }
    }

    .refund_policy {
      display: block;
      padding: 8px 0;
      color: #666;
      font-size: 12px;
      text-align: right;

      a {
        color: #555;
        font-weight: bold;
        text-decoration: none;
      }

      &.coupon_policy {
        position: absolute;
        bottom: 4px;
        right: 0;
      }
    }

    .coupon {
      border-bottom: 1px solid #ccc;
      margin: 0px;
      padding: 9px 16px;

      span {
        font-size: 14px;
      }
    }

    .coupon:last-child {
      margin: 0px;
    }

    .buttonContainer {
      position: relative;
      flex-basis: 100%;

      .buttons {
        display: flex;

        .buttonPay {
          width: 100%;
        }

        .btn_pay {
          border: 1px solid #fddd00;
          background: #fddd00;
          padding: 10px 60px;
          span {
            margin-right: 2px;
            font-weight: bold;
          }
        }
        .rules {
          text-align: left;
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .orderContainer {
    min-width: unset;
  }
}

@media screen and (max-width: 430px) {
  .orderContainer {
    width: 100%;
    max-width: 100% !important;
    padding: 0;

    .buttonClose {
      padding: 6px 6px 0 0;
    }

    h3 {
      font-size: 14px;
      margin: 2px 0px;
    }

    .programInfo {
      padding: 0 10px 10px;
      margin: 0 0 10px;

      .program_name {
        font-size: 14px;
        font-weight: bold;
      }

      .program_price {
        font-size: 15px;
      }
    }

    .priceWrap .buttonContainer .buttons .btn_pay {
      padding: 6px 30px;
    }
  }
}

@media screen and (max-width: 375px) {
  .orderContainer {
    h3 {
      font-size: 13px;
      margin: 2px 0px;
    }

    .programInfo {
      display: none;
    }
  
    .priceWrap {
      padding: 0 12px !important;
      border: none !important;
    }
  }
}

@media screen and (max-width: 320px) {
  .buttonContainer {
    padding-top: 0 !important;
  }
}