.NoticeWrap {
  max-width: 768px;
  margin: 32px auto;
  margin-top: 0px;

  .Notice {
    margin: 16px auto 128px;
    padding: 32px;
    min-height: 400px;

    .title {
      font-size: 16px;
      color: #333;
      margin: 4px 0 24px;
      border-bottom: 2px solid#fff;
      transition: 0.3s;
      direction: inline-block;

      &:hover {
        border-bottom: 2px solid #fe4240;
      }
    }

    small {
      margin: 16px 0 0;
    }
  }
}

.NoticeDetail {
  width: 768px;
  margin: 32px auto;

  .NoticePaper {
    padding: 64px;

    .contentHeader {
      font-size: 12px;
      display: flex;
      align-items: center;
      margin: 0 0 16px;

      a {
        color: #999;
        text-decoration: none;
        margin-right: 4px;
        transition: 0.3s;

        &:hover {
          color: #333;
        }
      }

      span {
        margin-left: 4px;
        color: #333;
      }
    }

    .writer {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin: 0 0 64px;

      .createdDate {
        color: #999;
        font-size: 12px;
      }
    }

    .content {
      margin: 0 0 32px;
      line-height: 1.8em;
      color: #333;

      .youtube_block {
        width: 80%;
        min-height: 320px;
        height: auto;
        margin: 16px auto;
        display: block;
      }

      ul {
        border: 1px solid #e9e9e9;
        padding: 16px 40px;
        background: #f9f9f9;
        border-radius: 4px;
      }

      a {
        font-weight: bold;
        color: #333;
        border: 1px solid #fddd00;
        padding: 1px 4px;
        border-radius: 4px;
        display: inline-block;
        transition: 0.3s;

        &:hover {
          background: #fddd00;
        }

        &:after {
          content: ' 🔗';
        }
      }

      img {
        max-width: 100%;
        margin: 16px 0;
      }
    }

    .pagination {
      border-top: 1px solid #e9e9e9;
      padding-top: 32px;

      a {
        margin: 0 8px;
        color: #333;
        transition: 0.3s;

        &:hover {
          color: #fe4240;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .NoticeWrap {
    margin: 18px;
    margin-top: 0px;

    .Notice {
      padding: 16px 0;
    }
  }

  .NoticeDetail {
    width: inherit;
    margin: 16px 16px;

    .NoticePaper {
      padding: 16px;

      h1 {
        font-size: 24px;
      }
    }
  }
}
