body {
  .menuHeader {
    margin: 0;

    h1 {
      font-weight: 700;
      font-size: 36px;
      min-height: 1.4em;
      color: #333;
      text-align: center;
      padding: 20px 0 10px;
      margin: 0;
    }

    h4 {
      text-align: center;
      margin: 0;
      font-weight: bold;
      color: #000;
      font-size: 1.2em;
    }

    p {
      margin: 0 0 16px;
    }

    &.backImg {
      margin: 0;

      h1 {
        color: #fff;
        text-shadow: 0 4px 8px #00000033;
        margin: 64px 0 0;
      }

      h4 {
        color: #fff;
        text-shadow: 0 1px 10px #00000099;
      }
    }
  }

  .memberHeader {
    h1 {
      font-weight: 700;
      font-size: 36px;
      min-height: 1.4em;
      color: #333;
      margin: 0;
    }

    h6 {
      margin: 12px 4px 18px 4px;
    }

    .caption {
      margin: 0px 4px;
    }
  }
}

.navWrapper {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0 !important;
  position: fixed !important;
  background-color: #fff !important;
  z-index: 10000 !important;
}

.nav {
  width: 100%;
  height: 60px;
  max-width: 940px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  top: 0 !important;
  position: fixed !important;
  background-color: #fff !important;
  z-index: 10000 !important;
  box-shadow: none !important;

  .logoWrapper {
    height: 56px;
    align-items: flex-start;
    border: 0 solid #000;
    box-sizing: border-box;

    .logo {
      height: 32px;
      padding: 10px 12px 10px 0px;
    }
  }

  .menu {
    justify-content: start;
    align-items: start;
  }

  .navMenu {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    box-sizing: border-box;

    .menuText {
      font-size: 14px;
      color: #666;
      text-decoration: none;
      width: 100%;
      text-align: left;
      padding-right: 50px;
      padding-left: 20px;

      &:hover {
        font-weight: bold;
        color: rgb(27, 28, 29);
      }

      @media screen and (max-width: 768px) {
        font-size: 15px;
      }
    }

    a {
      font-size: 13px;
      color: #333;
      text-decoration: none;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .loginBtn {
      display: inline-block;
      margin: 4px 12px 0px 12px;
      line-height: 2.3;
      font-size: 14px;
      color: #000;
      vertical-align: top;
    }

    .joinBtn {
      display: inline-block;
      height: 34px;
      margin: 5px 14px 0px 12px;
      padding: 1px 12px 0px 12px;
      border-radius: 4px;
      box-sizing: border-box;
      line-height: 2.2;
      font-size: 14px;
      font-weight: bold;
      color: #000;
      vertical-align: top;
      background-color: #fddd00;
    }

    .wishlistBtn {
      padding: 1px 6px;
      margin-right: 12px;
      margin-top: 1px;

      svg {
        width: 27px;
        height: 27px;
      }

      .icon {
        width: 27px;
        height: 27px;
      }
    }

    .navProfile {
      min-width: 40px;
      min-height: 40px;
      display: flex;
      align-items: center;
      padding: 1px 0px 0px 0px;
      text-decoration: none;
      background-color: transparent;

      .avatar {
        width: 27px;
        height: 27px;
        background: #cfcfcf;
        color: #fff;
        font-size: 1.25em;
      }

      svg {
        margin-bottom: 1px;
        width: 0.9em;
        height: 0.9em;
        color: #393939;
      }
    }
  }

  li {
    list-style: none;

    a {
      font-weight: 400;
      color: #333;
      text-decoration: none;
      font-weight: 600;
      transition: 0.3s;
    }
  }
}

@media screen and (max-width: 768px) {
  body {
    .nav {
      .logoWrapper {
        .logo {
          padding-left: 11px;
        }
      }
      .navMenu {
        margin-bottom: 5px;
        .loginBtn {
          margin: 5px 4px 0px 8px;
        }
        .wishlistBtn {
          margin-right: 9px;
          svg {
            width: 25px;
            height: 25px;
          }

          .icon {
            width: 25px;
            height: 25px;
          }
        }
        .navProfile {
          margin-right: 8px;
          .avatar {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }
}
