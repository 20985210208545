.fellowPageWrap {
  max-width: 975px;
  margin: 0px auto 50px;

  .sticky {
    position: fixed;
    top: 0;
    width: 100%;
  }

  .nameTitle {
    margin-left: 30px;
    margin-top: 20px;
    margin-bottom: 20px;
    position: relative;
    width: fit-content;

    .titleText {
      border: none;
      box-shadow: none;
      font-weight: 800;
      font-size: xx-large;
    }

    .line {
      border: 6px solid #fddb00;
      width: 100%;
      top: 25px;
      position: absolute;
      z-index: -1;
    }
  }
  .contentBox {
    display: flex;
    flex-direction: row;

    .fellowBox {
      padding-left: 15px;
    }

    .infoBox {
      display: flex;
      flex: 1;
      flex-direction: column;
      padding: 0px 25px;
      .contentDevider {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .fellowPageWrap {
    margin: 0px auto 15px;

    .nameTitle {
      margin-left: 20px;
      margin-top: 0px;
      max-width: 100%;

      .titleText {
        font-size: 27px;
      }

      .line {
        top: 20px;
      }
    }

    .contentBox {
      align-items: center;
      justify-content: center;
      margin: 0px;
      flex-direction: column;
      font-size: smaller;
      max-width: 100%;

      .fellowBox {
        margin: 10px 25px;
        padding: 0px;
        width: -webkit-fill-available;
      }

      .infoBox {
        max-width: 100%;
        margin: 10px;
        .contentDevider {
          display: block;
          width: 768px;
          border: 3px solid #f1f1f1f1;
        }
      }
    }
  }
}
