html {
  line-height: 1.15;
  text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  max-width: 100%;
  margin: auto;
  padding: 0;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: -1.2px;
  text-rendering: optimizeLegibility;
  backface-visibility: hidden;

  font-family: 'Noto Sans KR', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', 'Helvetica', 'Arial', 'Noto', 'Apple Gothic',
    'MalgunGothic', sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  span {
    letter-spacing: -1.2px;
    font-family: 'Noto Sans KR', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', 'Helvetica', 'Arial', 'Noto',
      'Apple Gothic', 'MalgunGothic', sans-serif;
    font-weight: 400;
  }
}

button {
  &:hover {
    cursor: pointer;
  }
  &:focus {
    outline: none;
  }
}

::selection {
  background-color: rgb(255, 89, 70) !important;
  color: rgb(255, 255, 255) !important;
}

.modalContainer {
  z-index: 99998 !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

#root {
  z-index: 0;
  height: 100%;
}

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  src: local('Noto Sans KR Regular'), local('NotoSansKR-Regular'), url('../fonts/noto-sans-kr-v12-korean-regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  src: local('Noto Sans KR Bold'), local('NotoSansKR-Bold'), url('../fonts/noto-sans-kr-v12-korean-700.woff2') format('woff2');
}

@font-face {
  font-family: 'Gmarket Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Gmarket Sans Bold'), local('GmarketSans-Bold'), url('https://script.ebay.co.kr/fonts/GmarketSansBold.woff2') format('woff2'),
    url('https://script.ebay.co.kr/fonts/GmarketSansBold.woff') format('woff'), url('../fonts/GmarketSansTTFBold.ttf') format('ttf');
}

@font-face {
  font-family: 'Gmarket Sans';
  font-style: normal;
  font-weight: 500;
  src: local('Gmarket Sans Medium'), local('GmarketSans-Medium'),
    url('https://script.ebay.co.kr/fonts/GmarketSansMedium.woff2') format('woff2'),
    url('https://script.ebay.co.kr/fonts/GmarketSansMedium.woff') format('woff'), url('../fonts/GmarketSansTTFMedium.ttf') format('ttf');
}

@font-face {
  font-family: 'Gmarket Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Gmarket Sans Light'), local('GmarketSans-Light'),
    url('https://script.ebay.co.kr/fonts/GmarketSansLight.woff2') format('woff2'),
    url('https://script.ebay.co.kr/fonts/GmarketSansLight.woff') format('woff'), url('../fonts/GmarketSansTTFLight.ttf') format('ttf');
}

@font-face {
  font-family: 'Impact';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/IMPACT.TTF') format('ttf');
}

@font-face {
  font-family: 'NeoDunggeunmo';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.3/NeoDunggeunmo.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'YangJin';
  font-style: normal;
  font-weight: normal;
  src: url('https://cdn.jsdelivr.net/gh/supernovice-lab/font@0.9/yangjin.woff') format('woff'), url('../fonts/YangJin.ttf') format('ttf');
}
