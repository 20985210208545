@import 'assets/scss/reset';

main {
  display: block;
  position: relative;
  flex: 1 1 auto;
  padding-top: 60px !important;
  margin: 0px auto;
  overflow: hidden;

  section {
    position: relative;
    align-items: stretch;
    padding: 0;
    border: 0 solid #000;
  }

  .homeContainer {
    margin: 0 auto;
    width: 100%;
    max-width: $max-width;
    position: relative;
    display: flex;
    flex-shrink: 0;
    flex-grow: 1;
    flex-flow: row nowrap;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;

    .scrollComponent {
      width: 100%;
      background-color: #f3f3f3;
      overflow: auto;
    }

    .guestContainer {
      background-color: #fff;
    }
  }
}

@media screen and (max-width: 768px) {
  main {
    section {
      border: 0 solid #000;
      .guestContainer {
        padding-bottom: 4px;
      }
    }
  }
}
