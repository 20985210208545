.youtube_block {
  width: 80%;
  min-height: 320px;
  height: auto;
  margin: 16px auto;
  display: block;
}

.qna_details {
  width: 100%;
  img {
    width: 100%;
  }
}

.faqPage {
  .tab {
    border-radius: 0px;
    border-bottom: 2px solid white;
  }
  .tab.active {
    border-bottom: 2px solid black;
    span {
      font-weight: bold;
    }
  }
}
